import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { DataProvider } from "hooks/useData";
import { ContextProvider } from "utilities/ContextProvider";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { ScreenProvider } from "hooks/useScreen";
import { TabProvider } from "hooks/useAssetsActiveTab";
import { UnityProvider } from "hooks/UseUnity";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_ENERGY_APP}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_ENERGY_APP}`,
  scope: "openid energy",
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  onSigninCallback: (user: any) => {
    window.history.replaceState(
      {},
      window.document.title,
      window.location.origin + window.location.pathname
    );
    window.location = user.state || "/";
  },
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppProvider>
        <UnityProvider>
          <TabProvider>
            <ScreenProvider>
              <DataProvider>
                <ContextProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </ContextProvider>
              </DataProvider>
            </ScreenProvider>
          </TabProvider>
        </UnityProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
