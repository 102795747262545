import { getNote, updateNote, createNote, deleteNote, downloadFile } from "api/unity";
import { SmallLoader } from "components/shared/Loader";
import saveAs from "file-saver";
import { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import { b64toBlob } from "./config/b64toBlob";
import { errorHandling } from "./config/errorHandling";
import { IFile, acceptedFormats } from "./utils/constants";
import { handleUpload } from "./config/HandleUpload";
import useApp from "hooks/useApp";

const NoteForm: any = ({
  noteDetails,
  noteId,
  closeHandler,
  updateNotes,
  pauseUnity,
}: any) => {
  // const { isScaled } = useScreen();
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [values, setValues] = useState<any>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [fileDownload, setFile] = useState<Blob | null>(null);
  const [fileDownloadName, setFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { userRole } = useApp();

  const [header, setHeader] = useState<any>("");
  const [note, setNote] = useState<any>("");

  const editMode = Boolean(noteId);


  useEffect(() => {
    pauseUnity();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (editMode) {
        const note = await getNote(noteId);
        setValues({
          ...values,
          id: note.data.id,
          x: note.data.x,
          y: note.data.y,
          z: note.data.z,
          files: note.data.files,
        });
        setHeader(note.data.header);
        setNote(note.data.note);
      }
    })();
    setLoading(false);
  }, [noteId]);

  const handleFile = (event: any) => {
    const uploadedFiles = event.target.files;
    const files: any = handleUpload(uploadedFiles, acceptedFormats);
    if (files) {
      setMessage([]);
      setError(false);
      setFiles(files);
    }
    else {
      setFiles([]);
      setMessage([]);
      setError(false);
    }

  };

  const NoteHandler = async (e: any) => {
    setSubmittingForm(true);
    e.preventDefault();
    if (editMode) {
      const formData = new FormData();
      formData.append("unityNote.Model.id", values.id);
      formData.append("unityNote.Model.header", header);
      if (note) formData.append("unityNote.Model.note", note);
      formData.append("unityNote.Model.x", values.x);
      formData.append("unityNote.Model.y", values.y);
      formData.append("unityNote.Model.z", values.z);
      if (values?.files) {
        values?.files.forEach((file: any, index: any) => {
          formData.append(
            `unityNote.Model.files[${index}].uniqueId`,
            file.uniqueId
          );
        });
      }
      files.forEach((file: any, index: any) => {
        formData.append(`unityNote.files[${index}].file`, file.file);
        formData.append(`unityNote.files[${index}].fileName`, file.fileName);
      });
      await updateNote(formData);
      updateNotes();
      setSubmittingForm(false);
    } else {
      const formData = new FormData();
      formData.append("unityNote.Model.header", header);
      if (note) formData.append("unityNote.Model.note", note);
      formData.append("unityNote.Model.x", noteDetails.x);
      formData.append("unityNote.Model.y", noteDetails.y);
      formData.append("unityNote.Model.z", noteDetails.z);
      files.forEach((file: any, index: any) => {
        formData.append(`unityNote.files[${index}].file`, file.file);
        formData.append(`unityNote.files[${index}].fileName`, file.fileName);
      });
      await createNote(formData);
      updateNotes();
      setSubmittingForm(false);
    }
    closeHandler();
  };


  const deleteHandler = async () => {
    setSubmittingForm(true);
    if (noteId) {
      const text =
        "Closing the window will delete the Note. Are you sure you want to close ?";
      if (window.confirm(text) === true) {
        await deleteNote(noteId);
        setValues(null);
        updateNotes();
      }
    }
    closeHandler();
  };

  const deleteFileHandler = (uniqueId: any) => {
    setValues((state: any) => {
      if (state?.files) {
        const files = state?.files.filter(
          (file: any) => file.uniqueId !== uniqueId
        );
        return { ...state, files: files };
      }
      return state;
    });
    updateNotes();
  };

  useEffect(() => {
    if (fileDownload && fileDownloadName) {
      const blob = b64toBlob(fileDownload);
      saveAs(blob, fileDownloadName);
      setMessage(["File downloaded successfully"]);
    }
  }, [fileDownload, fileDownloadName]);

  const downloadFileHandler = async (id: string) => {
    await downloadFile(id).then(
      (r: any) => {
        if (r.data.file) {
          setError(false);
          setFile(r.data.file);
          setFileName(r.data.fileName);
        } else if (r.data.error) {
          setError(true);
          setMessage([r.data.error]);
        }
      },
      (responseError) => {
        setMessage(errorHandling(responseError));
        setError(true);
      }
    );
  };

  return (
    <div className="fixed inset-0 z-10 w-full h-screen">
      <div
        className={classNames(
          "h-full",
          "absolute z-20 inset-0 w-full bg-black/70"
        )}
      />

      <div className={classNames("top-1/2", "w-full h-auto gradient p-0.5 rounded-md z-30 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px] max-h-[80vh]")}>
        <div className="bg-dark p-3 w-full h-full rounded-md">
          {loading && (
            <div className="w-full h-[300px] grid place-content-center">
              <SmallLoader />
            </div>
          )}
          {!loading && (
            <>
              {/* Header */}
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-green">Note</h1>
                <div
                  className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                  onClick={closeHandler}
                >
                  <FiX size={20} />
                </div>
              </div>
              {/* Content */}
              <div className="mt-5">
                {message.map((eachMessage, index) => (
                  <div className="pt-4" key={`ticket_${index}`}>
                    <p className={error ? "text-danger" : "text-success"}>
                      {eachMessage}
                    </p>
                  </div>
                ))}
                <form className="mt-3" onSubmit={NoteHandler}>
                  <h1 className="text-lg text-center font-semibold">
                    {editMode ? noteDetails?.header : "New Note"}
                  </h1>
                  <div className="mt-3">
                    <label htmlFor="header">Header</label>
                    <textarea
                      key="header"
                      id="header"
                      name="header"
                      placeholder="Header"
                      rows={1}
                      required={true}
                      className="input mt-1 resize-none"
                      value={header}
                      onChange={(e: any) => setHeader(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="note">Note</label>
                    <textarea
                      rows={5}
                      key="note"
                      id="note"
                      name="note"
                      placeholder="Note"
                      className="input mt-1 max-h-[28vh] overflow-auto"
                      value={note}
                      onChange={(e: any) => setNote(e.target.value)}
                    />
                  </div>
                  {!!values?.files?.length && (
                    <div className="my-3">
                      <label className="text-green text-lg">
                        Attached Files
                      </label>
                      {values?.files?.map((file: any) => (
                        <div
                          className="flex items-center my-1 gap-2"
                          key={file.uniqueId}
                        >
                          <div>
                            <span
                              className="underline cursor-pointer"
                              onClick={() => downloadFileHandler(file.uniqueId)}
                            >
                              {file.fileName}
                            </span>
                          </div>
                          <div
                            onClick={() => deleteFileHandler(file.uniqueId)}
                            className="underline cursor-pointer"
                          >
                            <FiX />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex flex-col mt-5">
                    <label htmlFor="name">Files</label>
                    <input
                      type="file"
                      multiple={true}
                      name="Notes_pdf"
                      onChange={handleFile}
                      className="input"
                      accept={acceptedFormats.join(",")}
                    />
                  </div>
                  <div className="flex flex-row-reverse mt-5 gap-5">
                    <input
                      name="create"
                      id="create"
                      key="create"
                      value={editMode ? "Update" : "Create"}
                      type="submit"
                      className="btn btn-fill btn-primary"
                      disabled={submittingForm}
                    />
                    {userRole === 'admin' && editMode && (
                      <input
                        name="delete"
                        id="delete"
                        key="delete"
                        value="Delete"
                        type="button"
                        onClick={deleteHandler}
                        className="btn"
                      />
                    )}
                    {submittingForm && <SmallLoader />}
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Content end */}
        </div>
      </div>
    </div>
  );
};
export default NoteForm;
