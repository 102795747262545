/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";

export const getBubblesMeta = () => {
  return energyApi.get("/UnityData/GetUnityBubbleMeta");
};

export const getBubblesData = () => {
  return energyApi.get("/UnityData/GetUnityBubbleData");
  // return api.get("/Unity/GetUnityBubbleData?dateTime=2023-10-02T22%3A46%3A00.000");
};

export const updateBubblePosition = (data: any) => {
  return energyApi.post("/Unity/UpdateBubbleCordinates", data);
};

export const getNotes = () => {
  return energyApi.get("/UnityData/GetUnityNoteMeta");
};

export const getEquipment = (id: any) => {
  return energyApi.get(`/UnityData/GetEquipment?id=${id}`);
};

export const getUnit = (id: any) => {
  return energyApi.get(`/UnityData/GetUnit?id=${id}`);
};

export const getNote = (id: any) => {
  return energyApi.get(`Unity/GetNote?id=${id}`);
};

export const getBubble = (id: any) => {
  return energyApi.get(`Unity/GetBubble?id=${id}`);
};

export const downloadFile = (id: any) => {
  return energyApi.get("/Unity/getFile" + `?id=${id}`);
};

// Notes

export const createNote = (data: any) => {
  return energyApi.post("/Unity/CreateNote", data);
};

export const updateNote = (data: any) => {
  return energyApi.post("/Unity/UpdateNote", data);
};

export const deleteNote = (id: any) => {
  return energyApi.get("/Unity/DeleteNote" + `?id=${id}`);
};

// Bubbles

export const createBubble = (data: any) => {
  return energyApi.post("/Unity/CreateBubble", data);
};

export const updateBubble = (data: any) => {
  return energyApi.post("/Unity/UpdateBubble", data);
};

export const deleteBubble = (id: any) => {
  return energyApi.get("/Unity/DeleteBubble" + `?id=${id}`);
};


export const getLabelsMeta = () => {
  return energyApi.get("/Unity/GetUnityLabelMeta");
};

export const getLabel = (id: any) => {
  return energyApi.get(`Unity/GetLabel?id=${id}`);
};

export const createLabel = (data: any) => {
  return energyApi.post("/Unity/CreateLabel", data);
};

export const updateLabel = (data: any) => {
  return energyApi.post("/Unity/UpdateLabel", data);
};

export const deleteLabel = (id: any) => {
  return energyApi.get("/Unity/DeleteLabel" + `?id=${id}`);
};

export const updateLabelPos = (data: any) => {
  return energyApi.post("/Unity/UpdateLabelCordinates", data);
};