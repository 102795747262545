import Loader from "components/shared/Loader";
import React from "react";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import KpiTab from "../../../../components/DynamicWidgets/KpiTab";
import useData from "hooks/useData";
import { Item } from "types/common";

const Kpi = ({ tab }: any) => {
  const { pastDate } = useData();
  const data = tab.configurations;
  const [k1, setK1] = useState<any>(null);
  const [k2, setK2] = useState<any>(null);
  const [k3, setK3] = useState<any>(null);
  const [k4, setK4] = useState<any>(null);

  const k1dataId = data.k1.id;
  const k2dataId = data.k2.id;
  const k3dataId = data.k3.id;
  const k4dataId = data.k4.id;

  const kpiData = [
    { id: k1dataId, data: k1, name: data.k1.name },
    { id: k2dataId, data: k2, name: data.k2.name },
    { id: k3dataId, data: k3, name: data.k3.name },
    { id: k4dataId, data: k4, name: data.k4.name },
  ];

  useEffect(() => {
    const fetchData = async () => {
      // K1
      if (k1dataId !== "") {
        if (data.k1.type === "tags") {
          const k1Res = await getTagData([k1dataId], pastDate);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        } else {
          const k1Res = await getExpressionData([k1dataId], pastDate);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        }
      }
      // K2
      if (k2dataId !== "") {
        if (data.k2.type === "tags") {
          const k2Res = await getTagData([k2dataId], pastDate);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        } else {
          const k2Res = await getExpressionData([k2dataId], pastDate);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        }
      }
      // K3
      if (k3dataId !== "") {
        if (data.k3.type === "tags") {
          const k3Res = await getTagData([k3dataId], pastDate);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        } else {
          const k3Res = await getExpressionData([k3dataId], pastDate);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        }
      }
      // K4
      if (k4dataId !== "") {
        if (data.k4.type === "tags") {
          const k4Res = await getTagData([k4dataId], pastDate);
          if (k4Res.status === 200) {
            setK4(k4Res.data[0]);
          }
        } else {
          const k4Res = await getExpressionData([k4dataId], pastDate);
          if (k4Res.status === 200) {
            setK4(k4Res.data[0]);
          }
        }
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [tab, pastDate]);

  return (
    <>
      {!data && (
        <div className="w-full h-full grid place-content-center">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-2 grid-rows-2 gap-3 h-full">
        {kpiData.map(
          ({ id, data, name }) =>
            id && <KpiTab key={id} data={data} name={name} />
        )}
      </div>
    </>
  );
};

export default React.memo(Kpi);
