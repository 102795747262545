import React, { FC, useState } from "react";
import { resetZoom, zoomIn, zoomOut, downloadCsv, downloadPdf, downloadPng } from "./utils/actions";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { FaSave } from "react-icons/fa";
import { PiFileCsv, PiFilePdf, PiFilePng, PiFile } from "react-icons/pi";
import { TbZoomOutArea } from "react-icons/tb";
import { SideBarProps, ButtonProps, ToggleButtonProps } from "./domain/types";
import classNames from "utilities/ClassNames";
import { areaConfig, barConfig } from "./config/configurations";
import { cn } from "@/lib/utils";

const ToggleMode: FC<ToggleButtonProps> = ({ isDaily, setIsDaily, sidebarType, setChartBtn }) => {

  const { buttonSize, buttonContainerClasses } = barConfig[sidebarType];

  const Button: FC<ButtonProps> = ({ isActive, onClick, children }) => (
    <div
      className={classNames(isActive ? "font-bold text-green" : "text-white/85", "cursor-pointer duration-300 text-[16px] leading-3", buttonSize)}
      onClick={onClick}
    >
      <span>{children}</span>
    </div>
  );

  return (
    <div className="w-[80%] flex justify-end">
      <div className={classNames("w-5", buttonContainerClasses, "flex flex-wrap justify-end gap-2")}>
        <Button isActive={isDaily} onClick={() => {
          setIsDaily(true);
          setChartBtn();
        }}>
          D
        </Button>
        <Button isActive={!isDaily} onClick={() => {
          setIsDaily(false);
          setChartBtn();
        }}>
          H
        </Button>
      </div>
    </div>
  );
};

const SideBar: FC<SideBarProps> = ({ chartRef, item, dataList, title, isDaily, setIsDaily, sidebarType, setChartBtn }) => {

  const { containerClasses } = barConfig[sidebarType];
  const [popoverShown, setPopoverShown] = useState(false);

  const popoverItems = [
    { Icon: PiFileCsv, action: () => downloadCsv(title || '', dataList || [], item) },
    { Icon: PiFilePdf, action: () => downloadPdf(chartRef, item) },
    { Icon: PiFilePng, action: () => downloadPng(chartRef, item) },
  ];

  const icons = [
    { Icon: FaSave, action: () => setPopoverShown((prev) => !prev) },
    { Icon: FiZoomIn, action: () => zoomIn(chartRef) },
    { Icon: FiZoomOut, action: () => zoomOut(chartRef) },
    { Icon: TbZoomOutArea, action: () => resetZoom(chartRef) },
  ];

  return (
    <div className={classNames(containerClasses, 'h-full flex flex-wrap justify-end items-end')}>
      <div className="flex-grow w-full relative">
        {icons.map(({ Icon, action }, index) => (
          <div
            key={index}
            className={cn((index === 0 && popoverShown) ? '' : '', "2xl:p-0.5 cursor-pointer hover:text-green duration-300 flex items-end justify-end")}
            onClick={action}
          >
            <Icon size={barConfig.small.iconSize} />
          </div>
        ))}
        {popoverShown && <div className="absolute 3xl:top-0 -top-1 right-[20px] 3xl:right-[24px]">
          {popoverItems.map(({ Icon, action }, index) => (
            <div
              key={index}
              className='p-1 text-white hover:text-green hover:cursor-pointer'
              onClick={action}
            >
              <Icon size={barConfig.small.iconSize} />
            </div>
          ))}
        </div>
        }
      </div>
      <div className="h-max mb-[30px]">
        <ToggleMode isDaily={isDaily} setIsDaily={setIsDaily} sidebarType={sidebarType} setChartBtn={setChartBtn} />
      </div>
    </div >
  );
};

export default SideBar;
