import React, { useState, useRef, useEffect } from "react";
import { AreaProps, Point } from "./domain/types";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "components/shared/Loader";
import { Line } from "react-chartjs-2";
import classNames from "utilities/ClassNames";
import getOptions from "./config/getOptions";
import SideBar from "./SideBar";
import getChartData from "./domain/chartDataService";
import getChartFutureData from "./domain/chartFutureDataService";
import getDataSet from "./utils/getDataSet";
import { areaConfig } from "./config/configurations";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  zoomPlugin,
  annotationPlugin,
);

const Area: React.FC<AreaProps> = ({ item, title, type, areaType, sidebarType = "regular", renderType, maintainAspectRatio }) => {
  const chartRef = useRef<ChartJS<"line", Point[]> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [valueSet, setValueSet] = useState<number[]>();
  const [dataList, setDataList] = useState<Point[]>();
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [isDaily, setIsDaily] = useState<boolean>(false);
  const [predictionList, setPredictionList] = useState<Point[]>();
  const [transitionPoint, setTransitionPoint] = useState<Point>({ x: 0, y: 0 });
  const [isPredictionShown, setIsPredictionShown] = useState(false);
  const [isPredictionReady, setIsPredictionReady] = useState(false);
  const [isDailyTriggered, setIsDailyTriggered] = useState(false);
  const [isPredictionLoading, setIsPredictionLoading] = useState(true);

  const { maxTicksLimit } = areaConfig[areaType];
  const itemId = item.id;

  const fetchChartData = async () => {
    setLoading(true);
    try {
      const { dataList, valueSet } = await getChartData({ type, itemId, isDaily, areaType });
      let predictionListItems: Point[] = [];

      if (renderType === 'regular') {
        const { predictionList } = await getChartFutureData({ type, itemId, isDaily, areaType });
        predictionListItems = predictionList || [];
        setPredictionList(predictionList);
      }
      setValueSet(valueSet);
      setDataList(dataList);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChartPredictionData = async () => {
    try {
      const { predictionList } = await getChartFutureData({ type, itemId, isDaily, areaType });
      setPredictionList(predictionList);
      setIsPredictionLoading(false);
      setIsPredictionReady(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchChartData();
    fetchChartPredictionData();
  }, [isDaily]);

  useEffect(() => {
    renderType === 'regular' && setIsPredictionShown(true);
  }, [renderType]);

  useEffect(() => {
    if (valueSet) {
      let values = valueSet;
      if (predictionList && isPredictionShown) {
        const predictionValues = predictionList.map((val: any) => val.y);
        values = [...values, ...predictionValues];
      }
      setMinValue(Math.min(...values));
      setMaxValue(Math.max(...values));
    }
  }, [valueSet, isPredictionShown, isDaily]);

  useEffect(() => {
    if (
      dataList &&
      dataList.length > 0 &&
      predictionList &&
      predictionList.length > 0 &&
      isPredictionShown
    ) {
      setPredictionList([dataList[dataList.length - 1], ...predictionList]);
    }
    if (dataList && dataList.length > 0) {
      setTransitionPoint({
        x: dataList[dataList.length - 1].x,
        y: dataList[dataList.length - 1].y,
      });
    }
  }, [dataList, isPredictionShown, isDaily]);

  useEffect(() => {
    if (isPredictionReady) {
      setTimeout(() => {
        setIsPredictionShown(true);
      }, 500);
    }
  }, [isPredictionReady]);

  const data = getDataSet({ item, dataList: dataList || [], predictionList: predictionList || [], minValue, maxValue, isPredictionShown });
  const firstY = dataList ? dataList[0] : 0;
  const options = getOptions(minValue, maxValue, transitionPoint, item, maxTicksLimit, maintainAspectRatio, firstY || 0);
  const bgColor = {
    id: `areaChart_${item.id}`,
    beforeDraw: (chart: any) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const setChartBtn = () => {
    if (renderType === 'lazyLoading' && isPredictionShown) {
      setIsDailyTriggered(true);
    }
  };

  return loading ?
    (<div className={classNames(areaType === 'unity' ? "min-h-[23vh]" : "min-h-[34vh]", "w-full h-full grid place-content-center")}>
      <Loader />
    </div>) :
    (
      <div
        className={classNames(areaType === "small" ? "" : "pb-2", areaType === 'unity' ? "h-[23vh]" : "h-full",
          "flex gap-1 w-full mx-auto justify-center items-center pt-6 h-full"
        )}
      >
        <div className="w-[92%] pl-2 h-full relative">
          <div className="relative h-full flex justify-center items-center">
            <Line
              ref={chartRef}
              plugins={[bgColor]}
              options={options}
              data={data}
              className="h-full"
            />
          </div>
        </div>
        <div className="flex flex-col text-white/50 gap-2 max-h-full 2xl:-mt-3 w-[6%] h-full">
          <SideBar chartRef={chartRef} item={item} dataList={dataList || []} title={title} isDaily={isDaily} setIsDaily={setIsDaily} sidebarType={sidebarType} setChartBtn={setChartBtn} />
        </div>
      </div >
    );
};

export default Area;
