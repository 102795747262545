import Loader from "components/shared/Loader";
import React, { useEffect, useState } from "react";
import { infoModalProps } from "./domain/types";
import infoModalConfig from "./config/infoModalConfig";
const InfoModal: React.FC<infoModalProps> = ({
  page,
  selectedTag,
  setSelectedTag,
  isLoading,
  refresh,
  type
}) => {

  const pageConfig = infoModalConfig[page];
  const { title } = pageConfig;
  const { action } = pageConfig[type];
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [isTextFull, setIsTextFull] = useState(true);

  const genActionName = () => {
    if (selectedTag?.value?.statusId === 'New') {
      return 'acknowledge';
    }
    if (selectedTag?.value?.statusId === 'Acknowledged') {
      return 'open';
    }
    if (selectedTag.value === null) {
      return 'acknowledge all alarms';
    }
  };

  useEffect(() => {
    if (page === 'alarms' && selectedTag.value === null) {
      setIsTextFull(false);
    }
  }, [selectedTag]);

  const genActionProps = () => {
    if (selectedTag.action === 'acknowledge') {
      return { id: selectedTag?.value?.id, status: selectedTag?.value?.statusId === 'New' ? 1 : 0 };
    }
    return selectedTag.value?.id;
  };

  return (
    <div className="w-full h-full bg-dark p-4 rounded-lg">
      <div className="flex flex-col gap-5 z-50">
        <div className="flex justify-center items-center flex-col gap-10 p-3 text-center">
          <p>
            Are you sure you want to {page === 'alarms' ? genActionName() : selectedTag.action} {isTextFull && `this ${title}`}?
          </p>
          {isLoadingProcess && <Loader />}
          <div className="flex gap-5">
            <button
              className="btn-sec"
              onClick={() => {
                setSelectedTag({ action: "", value: null });
              }}
            >
              cancel
            </button>
            <button className="btn" onClick={async () => {
              if (action) {
                try {
                  setIsLoadingProcess(true);
                  await action(page === 'alarms' ? genActionProps() : selectedTag.value?.id);
                  await refresh();
                  setIsLoadingProcess(false);
                  setSelectedTag({ action: "", value: null });
                } catch (e) {
                  console.error(e);
                }
              }
            }}>
              {page === 'alarms' ? genActionName() : selectedTag.action}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;