import { energyApi as api } from "utilities/Axios";

export const getFilesData = (file: any) => {
  const url = file ? `/FileExplorer/Get/?path=${file}` : '/FileExplorer/Get';
  return api.get(url);
};

export const uploadFile = (type: string, data: any) => {
  return api.post(`/FileManager/${type}`, data);
};

export const deleteFile = (id: string) => {
  return api.get(`/FileManager/DeleteByFileId/${id}`);
};

export const getFile = (id: string) => {
  return api.get(`/FileManager/file/${id}`);
};

export const getFiles = (id: string) => {
  return api.get(`/FileManager/file/${id}`);
};

export const getAllUniqueFiles = (type: string) => {
  return api.get(`/FileManager/GetList/${type}`);
};

export const uploadImagesForPointClouds = (data: any) => {
  return api.post(`/FileManager/UploadToExplorer/raw_drone_image`, data);
};

export const processDroneImages = () => {
  return api.get('/FileManager/ProcessDroneImages');
};

export const getFileByPath = (data: any) => {
  return api.post('/GetFileByPath', data, {
    responseType: 'blob' 
  });
};
