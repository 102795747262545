import { acceptAllAlarm } from "api/alarm";

const acknowledgeAllAlarmsService = async () => {
  const response: any = await acceptAllAlarm();
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default acknowledgeAllAlarmsService;