import Bar from "components/charts/Bar/Bar";
import Area from "components/charts/Area/Area";
import { MdQueryStats } from "react-icons/md";
import classNames from "utilities/ClassNames";
import GaugeBox from "./GaugeBox";
import useData from "hooks/useData";

import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";
import { Item } from "types/common";

export default function GaugeBar({ tab }: any) {
  const { pastDate } = useData();
  const [loading, setLoading] = useState(true);
  const data = tab?.configurations;
  const namesCollection = Object.entries(data).map((singleItem: any) => singleItem[1]?.name);
  const [allData, setAllData] = useState<Item[] | null>(null);

  const expressionsIds: any = [];
  const tagsIds: any = [];

  if (
    data &&
    data.k1 &&
    data.k1.value &&
    data.k1.id !== "" &&
    data.k1.type === "tags"
  ) {
    tagsIds.push(data.k1.id);
  } else if (data?.k1?.id && data?.k1?.id !== "") {
    expressionsIds.push(data.k1.value.id);
  }

  if (
    data &&
    data.k2 &&
    data.k2.value &&
    data.k2.id !== "" &&
    data.k2.type === "tags"
  ) {
    tagsIds.push(data.k2.id);
  } else if (data?.k2?.id && data?.k2?.id !== "") {
    expressionsIds.push(data.k2.value.id);
  }

  if (
    data &&
    data.k3 &&
    data.k3.value &&
    data.k3.id !== "" &&
    data.k3.type === "tags"
  ) {
    tagsIds.push(data.k3.id);
  } else if (data?.k3?.id && data?.k3?.id !== "") {
    expressionsIds.push(data.k3.value.id);
  }

  if (
    data &&
    data.k4 &&
    data.k4.value &&
    data.k4.id !== "" &&
    data.k4.type === "tags"
  ) {
    tagsIds.push(data.k4.id);
  } else if (data?.k4?.id && data?.k4?.id !== "") {
    expressionsIds.push(data.k4.value.id);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (tagsIds.length > 0) {
        const response: any = await getTagData(tagsIds, pastDate);
        if (response.status === 200) {
          setAllData(response.data);
        }
      } else if (expressionsIds.length > 0) {
        const response: any = await getExpressionData(expressionsIds, pastDate);
        if (response.status === 200) {
          setAllData(response.data);
        }
      }
    };

    fetchData();
    setLoading(false);
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [tab, pastDate]);

  const [current, setCurrent] = useState(0);
  const [chartType, setChartType] = useState("gauge");

  function changeChart() {
    if (chartType === "gauge") {
      setChartType("area");
    } else {
      setChartType("gauge");
    }
  }

  return (
    <>
      {data && (
        <div className="flex flex-col w-full h-full justify-center items-center">
          {/* Gauge */}
          <div
            className={classNames("h-[57.5%]",
              chartType === "area" ? "pt-0" : "",
              "flex relative w-full justify-center bg-dark p-1 rounded-md mb-2"
            )}
          >
            <div
              onClick={changeChart}
              className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300 absolute left-0 top-0"
            >
              <MdQueryStats size={20} />
            </div>
            {chartType === "gauge" && (
              <>
                {allData && allData[current] !== null && (
                  <>
                    {current === 0 && allData[0] && (
                      <GaugeBox data={allData[0]} name={namesCollection[0] || data?.k1?.name} />
                    )}
                    {current === 1 && allData[1] && (
                      <GaugeBox data={allData[1]} name={namesCollection[1] || data?.k2?.name} />
                    )}
                    {current === 2 && allData[2] && (
                      <GaugeBox data={allData[2]} name={namesCollection[2] || data?.k3?.name} />
                    )}
                    {current === 3 && allData[3] && (
                      <GaugeBox data={allData[3]} name={namesCollection[3] || data?.k4?.name} />
                    )}
                  </>
                )}
                {allData && allData[current] === null && (
                  <div className="w-full h-full grid place-content-center">
                    <h1>N/A</h1>
                  </div>
                )}
              </>
            )}
            {/* Area */}
            {allData && chartType === "area" && (
              <div className="w-[90%]">
                <Area
                  item={allData[current]}
                  type={allData[current].tagNameIdentifier ? "tag" : "expression"}
                  areaType={"small"}
                  sidebarType="small"
                  renderType="lazyLoading"
                  maintainAspectRatio={false}
                />
              </div>
            )}
          </div>

          {allData && (
            <div className="h-[41%] grid grid-cols-2 grid-rows-2 lg:gap-1 2xl:gap-3 w-full">
              {allData.map((item: Item, index: number) => (
                item && (
                  <div
                    key={index}
                    className=" h-full bg-dark rounded-md 2xl:py-0 px-2 cursor-pointer flex items-center"
                    onClick={() => {
                      setCurrent(index);
                      setChartType("gauge");
                    }}
                  >
                    <Bar
                      meta={item}
                      barType={"smallest"}
                      name={namesCollection[index]}
                    />
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}
