import axios from "axios";

import { User } from "oidc-client-ts";
function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_APP}:${process.env.REACT_APP_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

const AtttachTokenFunc = async (config: any) => {
  const user = getUser();
  const token = user?.access_token;
  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  return config;
};

const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_APP,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});
authApi.interceptors.request.use((config: any) => AtttachTokenFunc(config), (error: any) => Promise.reject(error));

const commonApi = axios.create({
  baseURL: process.env.REACT_APP_COMMON_API,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});
commonApi.interceptors.request.use((config: any) => AtttachTokenFunc(config), (error: any) => Promise.reject(error));

const energyApi = axios.create({
  baseURL: process.env.REACT_APP_ENERGY_API,
  validateStatus: (status: any) => {
    return status >= 200 && status < 500;
  },
});
energyApi.interceptors.request.use((config: any) => AtttachTokenFunc(config), (error: any) => Promise.reject(error));

export { authApi, commonApi, energyApi };



